import {observer} from 'mobx-react-lite';
import {useStore} from '@store/configureStore';
import permissionTypes from '@constants/permissionTypes';

const WithPermissions = ({optionalPermissions, children}) => {
  const {user} = useStore();

  if (
    user?.permissions?.includes(permissionTypes.AdminFullAccess) ||
    user?.permissions?.some((permission) =>
      optionalPermissions?.includes(permission),
    )
  ) {
    return children;
  }

  return null;
};

export default observer(WithPermissions);
