import {gql} from '@apollo/client';

export default {
  GET_BLOCKED_STATUS: gql`
    query Blacklist($uuid: UUID!) {
      bUserBlocked(uuid: $uuid)
    }
  `,
  TOGGLE_USER_BLOCK: gql`
    mutation Blacklist($input: BToggleUserBlockedInput!) {
      bToggleUserBlocked(input: $input)
    }
  `,
};
