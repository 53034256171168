import PageLoader from '@components/PageLoader';
import {useStore} from '@store/configureStore';
import persist from '@store/helpers/persist';
import {UserState} from '@store/UserStore';
import {AnimatePresence, motion} from 'framer-motion';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import ConfigService from '@services/ConfigService';
import refreshTokens from '@utils/refreshTokens';

const LoadingGate = ({children}) => {
  const [rehydrated, setRehydrated] = useState(false);
  const {user} = useStore();

  useEffect(() => {
    const init = async () => {
      try {
        await ConfigService.init();

        await persist({
          jsonify: true,

          whitelist: {
            user: Object.keys(UserState),
          },
        });

        if (user?.authorized) {
          refreshTokens();
        }
      } catch (e) {
        console.error('ERR_INIT_GLOBAL: ', e);
      } finally {
        setRehydrated(true);
      }
    };

    init();
  }, []);

  return (
    <AnimatePresence mode="wait">
      {rehydrated ? (
        <motion.div
          key={'content'}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.5}}>
          {children}
        </motion.div>
      ) : (
        <PageLoader />
      )}
    </AnimatePresence>
  );
};

export default observer(LoadingGate);
