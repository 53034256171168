import {gql} from '@apollo/client';

export default {
  VERIFY: gql`
    query Social($input: VerifyInput!) {
      verify(input: $input) {
        status
        strategy
        user {
          email
          picture
        }
      }
    }
  `,
};
