import {gql} from '@apollo/client';

export default {
  GET_WITHDRAWAL_LIST: gql`
    query Payment($input: BWithdrawalListInput!) {
      bWithdrawalList(input: $input) {
        list {
          uuid
          userUuid
          firstName
          lastName
          provider
          status
          createdAt
          amount
          currency
          approvedAmount
          paypalDetails {
            email
          }
          checkDetails {
            address
            apt
          }
          zelleDetails {
            account
          }
        }
        totalCount
      }
    }
  `,
  GET_DEPOSIT_LIST: gql`
    query Payment($input: BListInput!) {
      bDepositList(input: $input) {
        list {
          uuid
          userUuid
          firstName
          lastName
          email
          provider
          status
          createdAt
          updatedAt
          amount
          approvedAmount
          comment
          currency
        }
        totalCount
      }
    }
  `,
  CHANGE_WITHDRAWAL_STATUS: gql`
    mutation Payment($input: ChangeWithdrawalStatusInput!) {
      bChangeWithdrawalStatus(input: $input) {
        uuid
        provider
        status
      }
    }
  `,
  CHANGE_DEPOSIT_STATUS: gql`
    mutation Payment($input: ChangeDepositStatusInput!) {
      bChangeDepositStatus(input: $input) {
        uuid
        provider
        status
      }
    }
  `,
  GET_WALLET_BALANCE_HISTORY: gql`
    query History($input: BHistoryInput!) {
      bGetHistory(input: $input) {
        list {
          amount
          before
          after
          createdAt
          action
        }
      }
    }
  `,
  MANUAL_DEPOSIT: gql`
    mutation Payment($input: BManualDepositInput!) {
      bManualDeposit(input: $input) {
        uuid
        provider
        status
      }
    }
  `,
};
