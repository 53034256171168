import {Loader} from '@strapi/design-system';

const PageLoader = () => {
  return (
    <div className="fixed z-[999] flex h-screen w-full items-center justify-center">
      <Loader>Loading...</Loader>
    </div>
  );
};

export default PageLoader;
