import {onError} from '@apollo/client/link/error';
import {rootStore} from '@store/configureStore';

const errorAuthLink = onError(
  ({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'UNAUTHORIZED': {
            if (operation.getContext()?._skipRefreshToken) {
              rootStore?.user?.signOut();

              return;
            }
          }
        }
      }
    }
  },
);

export default errorAuthLink;
