import WithPermissions from '@components/WithPermissions';
import PermissionTypes from '@constants/permissionTypes';
import logo from '@images/logo-small.svg';
import routes from '@navigation/routes';
import {useStore} from '@store/configureStore';
import {
  Box,
  Button,
  Divider,
  MainNav,
  NavBrand,
  NavCondense,
  NavLink,
  NavSection,
  NavSections,
  NavUser,
} from '@strapi/design-system';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {
  AiFillDashboard,
  BiLogOut,
  FaStar,
  FaUsers,
  GiKeyring,
  GiPayMoney,
  GiReceiveMoney,
  GoSettings,
  VscServerProcess,
} from 'react-icons/all';
import {generatePath, useNavigate} from 'react-router-dom';

const MainSubNav = () => {
  const {user} = useStore();
  const navigate = useNavigate();
  const [condensed, setCondensed] = useState(false);

  useEffect(() => {
    user.getUserProfile.run();
  }, []);

  return (
    <Box
      background="neutral100"
      style={{
        height: '100vh',
      }}>
      <MainNav condensed={condensed}>
        <NavBrand
          style={{padding: 0}}
          workplace="Workplace"
          title="Backoffice Rival"
          icon={<img src={logo} alt="Rival" />}
        />
        <NavSections>
          <NavSection label="General">
            <NavLink
              to={routes.DASHBOARD}
              icon={<AiFillDashboard size="30px" />}>
              Dashboard
            </NavLink>
            <WithPermissions
              optionalPermissions={[
                PermissionTypes.ProfileFullAccess,
                PermissionTypes.ProfileList,
              ]}>
              <NavLink to={routes.USERS} icon={<FaUsers size="30px" />}>
                Users
              </NavLink>
            </WithPermissions>
          </NavSection>
          <WithPermissions
            optionalPermissions={[PermissionTypes.FantasybookConfig]}>
            <NavSection label="Fantasybook">
              <NavLink
                to={routes.FANTASY_BOOK_PLAYER_SETTINGS}
                icon={<FaStar size="30px" />}>
                Players setting
              </NavLink>

              <NavLink
                to={routes.FANTASY_BOOK_CONFIG}
                icon={<GoSettings size="30px" />}>
                Fantasybook config
              </NavLink>
            </NavSection>
          </WithPermissions>
          <WithPermissions
            optionalPermissions={[PermissionTypes.BingoTopUpConfig]}>
            <NavSection label="Fantasy Bingo">
              <NavLink
                to={routes.FANTASY_BINGO_CONFIG}
                icon={<GoSettings size="30px" />}>
                Fantasy Bingo config
              </NavLink>
            </NavSection>
          </WithPermissions>
          <WithPermissions
            optionalPermissions={[PermissionTypes.LeaguerFullAccess]}>
            <NavSection label="Best Ball">
              <NavLink
                to={routes.BEST_BALL_CONFIG}
                icon={<GoSettings size="30px" />}>
                Best Ball config
              </NavLink>
            </NavSection>
          </WithPermissions>
          <WithPermissions
            optionalPermissions={[
              PermissionTypes.PaymentFullAccess,
              PermissionTypes.DepositList,
              PermissionTypes.WithdrawalList,
            ]}>
            <NavSection label="Wallet">
              <WithPermissions
                optionalPermissions={[
                  PermissionTypes.PaymentFullAccess,
                  PermissionTypes.DepositList,
                ]}>
                <NavLink
                  to={routes.DEPOSITS}
                  icon={<GiReceiveMoney size="30px" />}>
                  Deposits
                </NavLink>
              </WithPermissions>
              <WithPermissions
                optionalPermissions={[
                  PermissionTypes.PaymentFullAccess,
                  PermissionTypes.WithdrawalList,
                ]}>
                <NavLink
                  to={routes.WITHDRAWALS}
                  icon={<GiPayMoney size="30px" />}>
                  Withdrawals
                </NavLink>
              </WithPermissions>

              {/*<NavLink*/}
              {/*  to={routes.WALLET_BALANCE_HISTORY}*/}
              {/*  icon={<BsCurrencyExchange size="30px" />}>*/}
              {/*  Wallet balance history*/}
              {/*</NavLink>*/}
            </NavSection>
          </WithPermissions>
          <WithPermissions
            optionalPermissions={[PermissionTypes.PermissionsFullAccess]}>
            <NavSection label="Permissions">
              <NavLink to={routes.PERMISSIONS} icon={<GiKeyring size="30px" />}>
                Permissions list
              </NavLink>
              <NavLink
                to={routes.GENERATE_TOKEN}
                icon={<VscServerProcess size="30px" />}>
                Generate JWT
              </NavLink>
              <span className="pb-[30px]" />
            </NavSection>
          </WithPermissions>
          <WithPermissions optionalPermissions={[PermissionTypes.CoreConfig]}>
            <NavSection label="Configs">
              <NavLink
                to={routes.PRODUCTS_CONFIG}
                icon={<GoSettings size="30px" />}>
                Products config
              </NavLink>
            </NavSection>
          </WithPermissions>
        </NavSections>
        <Divider />
        <div onClick={user.signOut} className="py-[16px] pl-[24px] pr-[20px]">
          {condensed ? (
            <BiLogOut
              size="16px"
              className="cursor-pointer text-[#8e8ea9] hover:text-[#4945ff]"
            />
          ) : (
            <Button fullWidth startIcon={<BiLogOut size="30px" />}>
              Logout
            </Button>
          )}
        </div>
        <NavUser
          src={
            user?.profile?.photo ||
            `https://avatars.dicebear.com/v2/male/${btoa(
              user?.profile?.userUuid,
            )}.svg`
          }
          onClick={() =>
            navigate(
              generatePath(routes.USER_PROFILE, {
                userUuid: user?.profile?.userUuid,
              }),
            )
          }>
          {user?.profile?.firstName
            ? `${user?.profile?.firstName} ${user?.profile?.lastName}`
            : user?.profile?.email}
        </NavUser>
        <NavCondense onClick={() => setCondensed((s) => !s)}>
          {condensed ? 'Expand the navbar' : 'Collapse the navbar'}
        </NavCondense>
      </MainNav>
    </Box>
  );
};

export default observer(MainSubNav);
