import {gql} from '@apollo/client';

export default {
  SIGN_IN: gql`
    mutation SignIn($input: SignInInput!) {
      signIn(input: $input) {
        __typename

        ... on JWTTokens {
          accessToken
          refreshToken
        }

        ... on EmailResult {
          sent
        }
      }
    }
  `,
  SOCIAL: gql`
    mutation SocialAuth($input: SocialAuthInput!) {
      socialAuth(input: $input) {
        __typename

        ... on JWTTokens {
          accessToken
          refreshToken
        }

        ... on EmailResult {
          sent
        }
      }
    }
  `,
  CONFIRM_EMAIL: gql`
    mutation Auth($input: ConfirmEmailInput!) {
      confirmEmail(input: $input) {
        accessToken
        refreshToken
      }
    }
  `,
  REFRESH_TOKEN: gql`
    mutation SignUp($input: JWTTokensInput!) {
      refreshTokens(input: $input) {
        accessToken
        refreshToken
      }
    }
  `,
  CHANGE_PASSWORD: gql`
    mutation Auth($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        email
        strategies
      }
    }
  `,
  GET_USER: gql`
    query Auth($uuid: UUID!) {
      bGetUser(uuid: $uuid) {
        uuid
        strategies
        email
        permissions {
          uuid
          name
          owned
        }
      }
    }
  `,
  GET_PERMISSIONS_LIST: gql`
    query Auth {
      bListPermissions {
        uuid
        name
        createdAt
      }
    }
  `,
  SET_PERMISSIONS: gql`
    mutation Auth($input: SetPermissionsInput!) {
      bSetPermissions(input: $input) {
        uuid
        strategies
        email
        permissions {
          uuid
          name
          owned
        }
      }
    }
  `,
  DELETE_PERMISSION: gql`
    mutation Auth($input: ActionPermissionInput!) {
      bDeletePermission(input: $input) {
        uuid
        name
        createdAt
      }
    }
  `,
  GENERATE_TOKEN: gql`
    mutation Auth($input: GenerateJWTInput!) {
      bGenerateJWT(input: $input)
    }
  `,
};
