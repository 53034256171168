import PageLoader from '@components/PageLoader';
import BaseLayout from '@layouts/BaseLayout';
import EmptyLayout from '@layouts/EmptyLayout';
import {lazy} from '@loadable/component';
import PrivateRoute from '@navigation/PrivateRoute';
import routes from '@navigation/routes';
import {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import AuthorizedRoute from '@navigation/AuthorizedRoute';
const FantasyBingoConfigPage = lazy(() => import('@pages/FantasyBingoConfig'));
const DashboardPage = lazy(() => import('@pages/Dashboard'));
const SignInPage = lazy(() => import('@pages/SignIn'));
const UserListPage = lazy(() => import('@pages/UserList'));
const UserProfilePage = lazy(() => import('@pages/UserProfile'));
const WithdrawalsPage = lazy(() => import('@pages/Withdrawals'));
const DepositsPage = lazy(() => import('@pages/Deposits'));
const PermissionsPage = lazy(() => import('@pages/Permissions'));
const GenerateTokenPage = lazy(() => import('@pages/GenerateToken'));
const NotFound = lazy(() => import('@pages/NotFound'));
const FantasybookPlayerSettingsPage = lazy(() =>
  import('@pages/FantasybookPlayerSettings'),
);
const FantasybookConfigPage = lazy(() => import('@pages/FantasybookConfig'));
const BestBallConfigPage = lazy(() => import('@pages/BestBallConfig'));
const WalletBalanceHistoryPage = lazy(() =>
  import('@pages/WalletBalanceHistory'),
);
const ProductsConfigPage = lazy(() => import('@pages/ProductsConfig'));

export default () => {
  return (
    <Routes>
      <Route
        path={routes.MAIN}
        element={
          <AuthorizedRoute>
            <Suspense fallback={<PageLoader />}>
              <EmptyLayout>
                <SignInPage />
              </EmptyLayout>
            </Suspense>
          </AuthorizedRoute>
        }
      />
      <Route
        path={routes.SIGN_IN}
        element={
          <AuthorizedRoute>
            <Suspense fallback={<PageLoader />}>
              <EmptyLayout>
                <SignInPage />
              </EmptyLayout>
            </Suspense>
          </AuthorizedRoute>
        }
      />
      <Route
        path={routes.USERS}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <UserListPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.DEPOSITS}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <DepositsPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.WITHDRAWALS}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <WithdrawalsPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.WALLET_BALANCE_HISTORY}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <WalletBalanceHistoryPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.DASHBOARD}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <DashboardPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.USER_PROFILE}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <UserProfilePage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.PERMISSIONS}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <PermissionsPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.GENERATE_TOKEN}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <GenerateTokenPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.FANTASY_BOOK_PLAYER_SETTINGS}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <FantasybookPlayerSettingsPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.FANTASY_BOOK_CONFIG}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <FantasybookConfigPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.FANTASY_BINGO_CONFIG}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <FantasyBingoConfigPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.BEST_BALL_CONFIG}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <BestBallConfigPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.PRODUCTS_CONFIG}
        element={
          <PrivateRoute>
            <Suspense fallback={<PageLoader />}>
              <BaseLayout>
                <ProductsConfigPage />
              </BaseLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<PageLoader />}>
            <BaseLayout>
              <NotFound />
            </BaseLayout>
          </Suspense>
        }
      />
    </Routes>
  );
};
