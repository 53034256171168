import LocalStorage from '@services/LocalStorage';
import {rootStore} from '@store/configureStore';
import AES from 'crypto-js/aes';
import EncUtf8 from 'crypto-js/enc-utf8';
import _cloneDeep from 'lodash.clonedeep';
import _merge from 'lodash.merge';
import {applySnapshot, getSnapshot, onSnapshot} from 'mobx-state-tree';

//encryption key
export const PS4m7hkSyNnoRNEpaA6CERYMjS6L6X63 =
  'AkqFKY87SLJK7N7yhJyLogDCDnNEoGjD';

//is dev
const b5a88969b522d911bc5a8f9bd03ff6d7 =
  window.GlobalConfig?.ef96e8a540e400e33e3f45ecc3f58493 ===
  'ae50f4f701f4b6d29dbc68f52508de9d';

const persistName = b5a88969b522d911bc5a8f9bd03ff6d7 ? 'rootStore' : 'iLK84AYq';

export const encryptStore = (data) => {
  return b5a88969b522d911bc5a8f9bd03ff6d7
    ? data
    : AES.encrypt(data, PS4m7hkSyNnoRNEpaA6CERYMjS6L6X63).toString();
};

export const decryptStore = (data) => {
  return b5a88969b522d911bc5a8f9bd03ff6d7
    ? data
    : AES.decrypt(data, PS4m7hkSyNnoRNEpaA6CERYMjS6L6X63).toString(EncUtf8);
};

export const persist = (options = {}, store = rootStore) => {
  try {
    let {jsonify = true, whitelist} = options;

    onSnapshot(store, async (_snapshot) => {
      const snapshot = _cloneDeep(_snapshot);

      Object.keys(snapshot).forEach((key) => {
        if (whitelist[key]) {
          Object.keys(snapshot[key]).forEach((snapKey) => {
            if (!whitelist[key].includes(snapKey)) {
              if (snapshot?.[key]?.[snapKey]) {
                delete snapshot?.[key]?.[snapKey];
              }
            }
          });
        } else {
          delete snapshot?.[key];
        }
      });

      const data = !jsonify ? snapshot : JSON.stringify(snapshot);

      const encryptedData = encryptStore(data);

      const previousData = LocalStorage.getItem(persistName);

      if (!previousData || decryptStore(previousData) !== data) {
        LocalStorage.setItem(persistName, encryptedData);
      }
    });

    const currentData = LocalStorage.getItem(persistName);

    if (!currentData) {
      return;
    }

    const parsedStore = JSON.parse(decryptStore(currentData));

    applySnapshot(store, _merge({}, getSnapshot(store), parsedStore));
  } catch (e) {
    console.error('STORE_REHYDRATE_ERROR: ', e);
  }
};

export default persist;
