export default {
  AUTH: window.GlobalConfig?.endpoints?.BASE + '/auth/graphql',
  PROFILE: window.GlobalConfig?.endpoints?.BASE + '/profile/graphql',
  WALLET: window.GlobalConfig?.endpoints?.BASE + '/wallet/graphql',
  ACTIVITY: window.GlobalConfig?.endpoints?.BASE + '/activity/graphql',
  FANTASY_BOOK: window.GlobalConfig?.endpoints?.BASE + '/fantasybook/graphql',
  FANTASY_BINGO: window.GlobalConfig?.endpoints?.BASE + '/bingo/graphql',
  TOKENATOR: window.GlobalConfig?.endpoints?.BASE + '/tokenator/graphql',
  BALANCE_UPDATER: window.GlobalConfig?.endpoints?.BASE_WS + '/wallet/graphql',
  PAYMENT: window.GlobalConfig?.endpoints?.BASE + '/payment/graphql',
  KYC: window.GlobalConfig?.endpoints?.BASE + '/kyc/graphql',
  LEAGUER: window.GlobalConfig?.endpoints?.BASE + '/leaguer/graphql',
  CORE: window.GlobalConfig?.endpoints?.BASE + '/core/graphql',

  TRANSLATION: window.GlobalConfig?.endpoints?.CMS + '/translation',
};
