import {gql} from '@apollo/client';

export default {
  GET_ADMIN: gql`
    query Profile {
      getProfile {
        userUuid
        email
        username
        photo
      }
    }
  `,
  GET: gql`
    query Profile($input: BProfileListInput!) {
      bProfileList(input: $input) {
        list {
          email
          userUuid
          username
          photo
          isOnline
          lastActive
          kyc {
            status
            birthDate
            firstName
            lastName
            city
            state
            country
            streetAddress
          }
        }
        totalCount
      }
    }
  `,
  GET_PROFILE: gql`
    query Profile($uuid: UUID!) {
      bGetProfile(uuid: $uuid) {
        userUuid
        username
        photo
        isOnline
        lastActive
        email
        createdAt
      }
    }
  `,
  CREATE_NOTE: gql`
    mutation Profile($input: BCreateNoteInput!) {
      bCreateNote(input: $input) {
        uuid
        userUuid
        text
        creator {
          username
        }
        createdAt
        updatedAt
      }
    }
  `,
  DELETE_NOTE: gql`
    mutation Profile($uuid: UUID!) {
      bDeleteNote(uuid: $uuid)
    }
  `,
  GET_NOTE_LIST: gql`
    query Profile($input: BNoteListInput!) {
      bNoteList(input: $input) {
        list {
          uuid
          userUuid
          text
          creator {
            username
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  `,
};
