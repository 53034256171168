import {gql} from '@apollo/client';

export default {
  VERIFY_MANUAL: gql`
    mutation Kyc($userUuid: UUID!, $input: DataVerificationInput!) {
      bVerifyManual(userUuid: $userUuid, input: $input) {
        userUuid
        status
      }
    }
  `,
  CHANGE_VERIFICATION_STATUS: gql`
    mutation Kyc($input: ChangeStatusInput!) {
      bChangeStatus(input: $input) {
        userUuid
        status
      }
    }
  `,
  GET_VERIFICATION_INFO: gql`
    query Kyc($userUuid: UUID!) {
      bGetStatus(userUuid: $userUuid) {
        kyc {
          userUuid
          firstName
          lastName
          birthDate
          streetAddress
          status
          restricted
          whitelisted
          updatedAt
          country
          state
          city
          zip
        }
        attempts
        type
        latestGeoHint {
          country
          state
          streetAddress
          restricted
          createdAt
        }
      }
    }
  `,
  GET_VERIFICATION_LIST: gql`
    query Kyc($input: BListUsersInput!) {
      bListUsers(input: $input) {
        list {
          userUuid
          status
          address
          apt
          fullName
          birthDate
          updatedAt
        }
        totalCount
      }
    }
  `,
  GC_VERIFICATION_WHITELIST: gql`
    mutation Kyc($userUuid: UUID!) {
      bToggleWhitelisted(userUuid: $userUuid) {
        userUuid
        status
        whitelisted
      }
    }
  `,
  GET_LIST_GEO_HINT: gql`
    query Kyc($userUuid: UUID!) {
      bListGeoHint(userUuid: $userUuid) {
        country
        state
        streetAddress
        restricted
        createdAt
      }
    }
  `,
};
