import {ApolloProvider} from '@apollo/client';
import {ChakraProvider} from '@chakra-ui/react';
import LoadingGate from '@components/LoadingGate';
import {apolloClient} from '@graphql';
import MainNavigator from '@navigation/MainNavigator';
import {MSTProvider, rootStore} from '@store/configureStore';
import React from 'react';
import {ThemeProvider, lightTheme} from '@strapi/design-system';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import 'regenerator-runtime/runtime';
import theme from './theme';
import './index.css';
import './theme.css';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraProvider {...{theme}}>
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <MSTProvider value={rootStore}>
            <ThemeProvider theme={lightTheme}>
              <LoadingGate>
                <MainNavigator />
              </LoadingGate>
            </ThemeProvider>
          </MSTProvider>
        </ApolloProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
);
