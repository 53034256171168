import MainSubNav from '@components/MainSubNav';
import NotificationContainer from '@components/Notification/NotificationContainer';
import {Box, Layout} from '@strapi/design-system';

const BaseLayout = ({children}) => {
  return (
    <Box background="neutral100">
      <Layout sideNav={<MainSubNav />}>
        <div className="h-[calc(100vh-56px)] overflow-scroll">{children}</div>
      </Layout>
      <NotificationContainer />
    </Box>
  );
};

export default BaseLayout;
