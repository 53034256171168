import {ApolloClient, from, InMemoryCache} from '@apollo/client';
import {authLink, errorAuthLink, errorLink, httpLink} from '@graphql';

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
  link: from([errorLink, errorAuthLink, authLink, httpLink]),
});

export default apolloClient;
