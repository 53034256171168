import {gql} from '@apollo/client';

export default {
  GET_USER_ACTIVITY: gql`
    query Activity($input: BListInput!) {
      bGetListByUser(input: $input) {
        list {
          type
          tags {
            icon
            title
          }
          meta
          createdAt
        }
        totalCount
      }
    }
  `,
};
