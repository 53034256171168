export default {
  MAIN: '/',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  VERIFICATION: '/verification',
  WITHDRAWALS: '/withdrawals',
  DEPOSITS: '/deposits',
  WALLET_BALANCE_HISTORY: '/wallet-balance-history',
  USER_PROFILE: '/user/:userUuid',
  PERMISSIONS: '/permissions',
  GENERATE_TOKEN: '/generate-token',
  FANTASY_BOOK_PLAYER_SETTINGS: '/fantasybook-player-settings',
  FANTASY_BOOK_CONFIG: '/fantasybook-config',
  FANTASY_BINGO_CONFIG: '/fantasy-bingo-config',
  BEST_BALL_CONFIG: '/best-ball-config',
  PRODUCTS_CONFIG: '/products-config',

  NOT_FOUND: '/404',
};
