import {split} from '@apollo/client';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import tokenTypes from '@constants/tokenTypes';
import LocalStorage from '@services/LocalStorage';
import {createUploadLink} from 'apollo-upload-client';
import {createClient} from 'graphql-ws';
import endpoints from '@api/endpoints';

const wsLink = new GraphQLWsLink(
  createClient({
    url: endpoints.BALANCE_UPDATER,
    connectionParams: {
      Authorization: LocalStorage.getItem(tokenTypes.ACCESS),
    },
  }),
);

const uploadLink = createUploadLink({
  uri: 'http://localhost:3000',
});

const link = split(
  ({query}) => {
    const {kind, operation} = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  uploadLink,
);

export default link;
