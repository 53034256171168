import {gql} from '@apollo/client';

export default {
  GET_CONFIG: gql`
    query Fantasybook {
      bGetConfig {
        uuid
        data
        createdAt
        createdBy
      }
    }
  `,
  UPDATE_CONFIG: gql`
    mutation Fantasybook($input: BUpdateConfigInput!) {
      bUpdateConfig(input: $input) {
        uuid
        data
        createdAt
        createdBy
      }
    }
  `,
  GET_PLAYERS_LIST: gql`
    query ListPlayers($input: ListPlayersInput!) {
      bListPlayers(input: $input) {
        totalCount
        list {
          uuid
          firstName
          lastName
          totalPoints
          blacklisted
          team {
            abbreviation
          }
        }
      }
    }
  `,
  TOGGLE_BLACK_LIST: gql`
    mutation ListPlayers($input: BToggleBlacklistInput!) {
      bToggleBlacklist(input: $input)
    }
  `,
  EDIT_CONFIG: gql`
    mutation EditConfig($input: BEditConfigInput!) {
      bEditConfig(input: $input)
    }
  `,
};
