import {gql} from '@apollo/client';

export default {
  GET_LIST_SLATES: gql`
    query BListSlates($input: BListSlatesInput!) {
      bListSlates(input: $input) {
        uuid
        coreLeague
        name
        cutoffAt
        repeatDeadline
        leagueTemplates
      }
    }
  `,
  DELETE_SLATE: gql`
    mutation Mutation($uuid: UUID!) {
      bDeleteSlate(uuid: $uuid)
    }
  `,
  CREATE_NEW_SLATE: gql`
    mutation Mutation($input: BCreateSlateInput!) {
      bCreateSlate(input: $input)
    }
  `,
  GET_LIST_TEMPLATES: gql`
    query Query($coreLeague: CoreLeague!) {
      bListTemplates(coreLeague: $coreLeague)
    }
  `,
};
