import {gql} from '@apollo/client';

export default {
  GET_USER_BALANCE: gql`
    query Balance($uuid: UUID!) {
      bGetBalance(uuid: $uuid) {
        uuid
        balance
        currency
      }
    }
  `,
  INCREMENT_USER_BALANCE: gql`
    mutation Wallet($input: DepositInput!) {
      bDeposit(input: $input) {
        uuid
        currency
        balance
      }
    }
  `,
  DECREMENT_USER_BALANCE: gql`
    mutation Wallet($input: DepositInput!) {
      bWithdraw(input: $input) {
        uuid
        currency
        balance
      }
    }
  `,
  ASSIGN_VOUCHER: gql`
    mutation Wallet($input: AssignVoucherInput!) {
      bAssignVoucher(input: $input)
    }
  `,
};
