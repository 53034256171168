import endpoints from '@api/endpoints';
import tokenTypes from '@constants/tokenTypes';
import {apolloClient, AUTH} from '@graphql';
import LocalStorage from '@services/LocalStorage';
import {rootStore} from '@store/configureStore';

const refreshTokens = async () => {
  const {data} = await apolloClient.mutate({
    mutation: AUTH.REFRESH_TOKEN,
    variables: {
      input: {
        accessToken: LocalStorage.getItem(tokenTypes.ACCESS),
        refreshToken: LocalStorage.getItem(tokenTypes.REFRESH),
      },
    },
    context: {
      uri: endpoints.AUTH,
    },
  });

  await rootStore.user.setTokens(
    data?.refreshTokens?.accessToken,
    data?.refreshTokens?.refreshToken,
  );
};

export default refreshTokens;
