export default {
  ActivityCreate: 'ActivityCreate',
  ActivityFullAccess: 'ActivityFullAccess',
  AdminFullAccess: 'AdminFullAccess',
  AuthFullAccess: 'AuthFullAccess',
  AuthGetUser: 'AuthGetUser',
  BingoConfig: 'BingoConfig',
  BlacklistAddToken: 'BlacklistAddToken',
  BlacklistFullAccess: 'BlacklistFullAccess',
  FantasybookConfig: 'FantasybookConfig',
  FavoriteFullAccess: 'FavoriteFullAccess',
  FavoriteListPlayers: 'FavoriteListPlayers',
  KycFullAccess: 'KycFullAccess',
  LeaguerFullAccess: 'LeaguerFullAccess',
  PaymentFullAccess: 'PaymentFullAccess',
  PermissionsFullAccess: 'PermissionsFullAccess',
  ProfileByUuid: 'ProfileByUuid',
  ProfileFullAccess: 'ProfileFullAccess',
  ProfileList: 'ProfileList',
  ProfileNoteCreate: 'ProfileNoteCreate',
  ProfileNoteDelete: 'ProfileNoteDelete',
  ProfileNoteList: 'ProfileNoteList',
  ProfileTagCreate: 'ProfileTagCreate',
  ProfileTagDelete: 'ProfileTagDelete',
  ProfileTagList: 'ProfileTagList',
  WalletByUuid: 'WalletByUuid',
  WalletDeposit: 'WalletDeposit',
  WalletFullAccess: 'WalletFullAccess',
  WalletWithdraw: 'WalletWithdraw',
  WithdrawalChangeStatus: 'WithdrawalChangeStatus',
  DepositChangeStatus: 'DepositChangeStatus',
  WithdrawalList: 'WithdrawalList',
  DepositList: 'DepositList',
  ChallengesFullAccess: 'ChallengesFullAccess',
  CreateChat: 'CreateChat',
  DeleteChat: 'DeleteChat',
  PaymentManualDeposit: 'PaymentManualDeposit',
  BingoTopUpConfig: 'BingoTopUpConfig',
  CoreConfig: 'CoreConfig',
};
