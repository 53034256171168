import {setContext} from '@apollo/client/link/context';
import tokenTypes from '@constants/tokenTypes';
import LocalStorage from '@services/LocalStorage';

const authLink = setContext(async (_, {headers, useAuth}) => {
  if (useAuth) {
    const accessToken = await LocalStorage.getItem(tokenTypes.ACCESS);

    if (accessToken) {
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    }
  }
});

export default authLink;
