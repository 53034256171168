import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { rootStore } from '@store/configureStore';
import refreshTokens from '@utils/refreshTokens';

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'UNAUTHORIZED':
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;

              forward$ = fromPromise(
                (async () => {
                  try {
                    refreshTokens();

                    resolvePendingRequests();

                    return true;
                  } catch (error) {
                    pendingRequests = [];
                    isRefreshing = false;

                    rootStore?.user?.signOut();

                    throw error;
                  } finally {
                    isRefreshing = false;
                  }
                })(),
              ).filter((value) => Boolean(value));
            } else {
              forward$ = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve());
                }),
              );
            }

            operation.setContext({
              _skipRefreshToken: true,
            });

            return forward$.flatMap(() => forward(operation));
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

export default errorLink;
