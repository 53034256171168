import endpoints from '@api/endpoints';
import authStrategyTypes from '@constants/authStrategyTypes';
import tokenTypes from '@constants/tokenTypes';
import {apolloClient, AUTH, PROFILE} from '@graphql';
import routes from '@navigation/routes';
import LocalStorage from '@services/LocalStorage';
import {returningAsyncAction} from '@store/helpers/asyncAction';
import {types} from 'mobx-state-tree';
import jwt_decode from 'jwt-decode';

export const UserState = {
  authorized: false,
  profile: null,
  permissions: null,
};

const completeAuth = async (data, store, navigate) => {
  store.setTokens(data?.accessToken, data?.refreshToken);
  store.setAuthorized(true);

  navigate(routes.USERS, {replace: true});

  store?.getUserProfile?.run();
};

function socialAuth({strategy, token, navigate}) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: AUTH.SOCIAL,
        variables: {
          input: {
            strategy,
            token,
            secured: true,
          },
        },
        context: {
          uri: endpoints.AUTH,
        },
      });

      return await completeAuth(data?.auth, store, navigate);
    } catch (error) {
      throw error;
    }
  };
}

function signIn({email, strategy, password, navigate}) {
  return async function flow(store) {
    let inputData = {strategy, email, password};

    if (strategy === authStrategyTypes.MAGIC_LINK) {
      delete inputData?.password;
    }

    try {
      const {data} = await apolloClient.mutate({
        mutation: AUTH.SIGN_IN,
        variables: {
          input: {
            ...inputData,
            secured: true,
          },
        },
        context: {
          uri: endpoints.AUTH,
        },
      });

      if (data?.signIn?.accessToken) {
        return await completeAuth(data?.auth, store, navigate);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };
}

function confirmEmail({email, strategy, code, navigate}) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: AUTH.CONFIRM_EMAIL,
        variables: {
          input: {
            ...{strategy, email, code},
            secured: true,
          },
        },
        context: {
          uri: endpoints.AUTH,
        },
      });

      await completeAuth(data?.confirmEmail, store, navigate);
    } catch (error) {
      throw error;
    }
  };
}

function getUserProfile() {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.query({
        query: PROFILE.GET_ADMIN,
        context: {
          useAuth: true,
          uri: endpoints.PROFILE,
        },
      });

      store.setUserProfile(data?.getProfile);
    } catch (e) {}
  };
}

function updateUserProfile(fields) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: PROFILE.UPDATE,
        variables: {
          input: {
            ...Object.fromEntries(
              Object.entries(fields).filter(([_, value]) => value !== ''),
            ),
          },
        },
        context: {
          useAuth: true,
          uri: endpoints.PROFILE,
        },
      });

      store.setUserProfile(data?.updateProfile);
    } catch (error) {
      throw error;
    }
  };
}

function uploadUserPhoto(file) {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: PROFILE.UPLOAD_PHOTO,
        variables: {file},
        context: {
          useAuth: true,
          uri: endpoints.PROFILE,
        },
      });

      store.setUserProfile(data?.uploadPhoto);
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function deleteUserPhoto() {
  return async function flow(store) {
    try {
      const {data} = await apolloClient.mutate({
        mutation: PROFILE.DELETE_PHOTO,
        variables: '',
        context: {
          useAuth: true,
          uri: endpoints.PROFILE,
        },
      });

      store.setUserProfile(data?.deletePhoto);
    } catch (e) {
      throw e?.response?.data;
    }
  };
}

function changePassword(newPassword) {
  return async function flow() {
    try {
      const {data} = await apolloClient.mutate({
        mutation: AUTH.CHANGE_PASSWORD,
        variables: {
          input: {...newPassword},
        },
        context: {
          useAuth: true,
          uri: endpoints.AUTH,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
}

const User = types
  .model({
    authorized: types.boolean,
    profile: types.maybeNull(types.frozen()),
    permissions: types.maybeNull(types.frozen()),
    kycVerified: types.maybeNull(types.boolean),

    socialAuth: returningAsyncAction(socialAuth),
    signIn: returningAsyncAction(signIn),
    confirmEmail: returningAsyncAction(confirmEmail),
    changePassword: returningAsyncAction(changePassword),
    getUserProfile: returningAsyncAction(getUserProfile),
    updateUserProfile: returningAsyncAction(updateUserProfile),
    deleteUserPhoto: returningAsyncAction(deleteUserPhoto),
    uploadUserPhoto: returningAsyncAction(uploadUserPhoto),
  })
  .actions((store) => ({
    setTokens(token, refreshToken) {
      LocalStorage.setItem(tokenTypes.ACCESS, token);
      LocalStorage.setItem(tokenTypes.REFRESH, refreshToken);

      if (token) {
        const decoded = jwt_decode(token);
        store.permissions = decoded?.permissions;
      }
    },
    setAuthorized(isAuthorized) {
      store.authorized = isAuthorized;
    },
    setUserProfile(profile) {
      store.profile = profile;
    },
    async signOut() {
      Object.entries(UserState).forEach(([key, value]) => (store[key] = value));

      LocalStorage.removeItem(tokenTypes.ACCESS);
      LocalStorage.removeItem(tokenTypes.REFRESH);

      window.location.replace(routes.MAIN);
    },
  }));

export default User;
