import {gql} from '@apollo/client';

export default {
  GET_LIST_TIMEFRAMES: gql`
    query BListTimeframes($input: BListTimeframesInput!) {
      bListTimeframes(input: $input) {
        uuid
        globalTimeframeId
        type
        coreLeague
        seasonType
        season
        week
        name
        startAt
        endAt
        firstGameStartAt
        lastGameEndAt
        apiSeason
        apiWeek
      }
    }
  `,
  GET_CONFIG: gql`
    query GetConfig($key: ConfigKey!) {
      getConfig(key: $key)
    }
  `,
  UPDATE_CONFIG: gql`
    mutation BUpdateConfig($input: BUpdateConfigInput!) {
      bUpdateConfig(input: $input)
    }
  `,
};
