import {gql} from '@apollo/client';

export default {
    GET_TOP_UP_CONFIG: gql`
        query Bingo($input: BGetTopUpConfig!) {
          bGetTopUpConfig(input: $input) {
              coreLeague
              minimumAmount
          }
        }
    `,
    UPDATE_TOP_UP_CONFIG: gql`
        mutation Bingo($input: BUpdateTopUpConfigInput!) {
          bUpdateTopUpConfig(input: $input)
        }
    `,
};
